import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Section, SearchWrap, SearchInput, SectionPageTitle, PaginationNav, Pagination, PageItem, PageLink } from "../components/Section"
import { SearchIcon} from "../components/Icons"
import styled from "styled-components"
import ProductCard from "../components/ProductCard"
import QuotePopup from "../components/QuotePopup"
let pageCount = 10;
let pageStart = 10;
let startVal = 0;
let currentPage1 = 1;
const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; 
  margin-bottom:60px;
  &:after{	
      display:none;
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: #fff;
      z-index: 0;
      width: 460px;
      height: 460px;
      border: 60px solid rgba(221,228,240,0.5);
      @media (min-width: 1600px){
        display:block;
        left: calc(100% - 350px);
      }
      @media (min-width: 1740px){
        border: 80px solid rgba(221,228,240,0.5);
        left: calc(100% - 290px);
      }
  }
`
const _ = require("lodash")

function Search ({ data, location, pageContext }) {
    const [name, setName] = useState("");
    const [isVisibleQuote, setQuoteVisible] = useState(false);
    // console.log(pageContext,"pageContext")
    const allProducts = data.allContentfulProduct.edges;
    const productsData = data.allContentfulProduct.edges
    const { currentPage, pageNum, alldata } = pageContext
    // console.log(location,currentPage,pageNum,"location");
    
    
    const productsPerPage = 10;
    let pageNum1 = Math.ceil(productsData.length / productsPerPage);

    // const isFirst = currentPage === 1
    // const isLast = currentPage === pageNum1
    // const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    // const nextPage = (currentPage + 1).toString()

    /* commented  Risto code*/
    // let getSerchName = "";
    // let splitResult = "";


    // if (typeof window !== "undefined") {
    //   getSerchName = location.search;
    //   splitResult = getSerchName.split('=')[1];
    // }
     
    // pageCount = pageCount*currentPage;
    /* end*/

    const getSerchName = location.search
    let splitResult = getSerchName.split('=')[1];
    
    const handleSubmit =  (event) => {
      event.preventDefault();
    }

    useEffect(() => {
      if(splitResult!=undefined) {
        setName(splitResult);
      }    
    }, [])

    let checkData = productsData.filter(product => {
      if (name === '') {
        return false;
      } else if (product.node.productName.toLowerCase().includes(name.toLowerCase())) {
        return product.node.productName;
      } 
      else if (product.node.productSku.toLowerCase().includes(name.toLowerCase())) {
        return product.node.productSku;
      } 
    });

    if(typeof currentPage === undefined) {
      currentPage = 1;
    }
    if(typeof pageNum === undefined){
      pageNum = Math.ceil(checkData.length / productsPerPage);
    }

    pageCount = pageCount*currentPage;
    pageNum1 = Math.ceil(checkData.length / productsPerPage);
    
    currentPage1 = (location.pathname === '/search')? 1 :currentPage;
  //  console.log(checkData,"checkData",pageNum1,splitResult)

   if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

    return (
      <Layout location={location}>
        <Seo
          title="Search Results | Coast to Coast Carports"
          description="Explore customized search results at Coast to Coast Carports. Find ideal solutions for your needs and secure your vehicles with our high-quality products."
        />
        <Section 
          pt="215px"
          pb="90px"
          xpt="120px"
          xpb="60px"
          bgColor="#F2F4F9">
          <SearchWrap>
            <div className="container">
              <SectionPageTitle>Product Search Results</SectionPageTitle>
              <form onSubmit = {handleSubmit} >
                <SearchInput className='search' mb="30px">
                  <div className="input-icon right">
                    <input
                      className="form-control"
                      type="text"
                      id="headerSearchInput"
                      value={name}
                      placeholder="Search Your Building..."
                      onChange={e => setName(e.target.value)}
                      required
                    />
                    <span className="icon"><SearchIcon /></span>
                  </div>
                </SearchInput>
              </form>
              {
                (checkData.length>0)?
                 ( productsData.filter(product => {

                    if (name === '') {
                      return product;
                    } else if (product.node.productName.toLowerCase().includes(name.toLowerCase())) {
                      return product.node.productName;
                    } 
                    else if (product.node.productSku.toLowerCase().includes(name.toLowerCase())) {
                      return product.node.productSku;
                    } 
                  }).map((product, i) => {
                    // console.log(pageStart*currentPage1)
                    if(currentPage1==1) {
                      startVal = 0;
                    } else {
                      startVal = (currentPage1*10)-10;
                    }
                    // console.log(i,">=",startVal ,"&&", i,"<=",pageStart*currentPage1,"111");
                    if (i>=startVal && i<=pageStart*currentPage1) {
                    return(
                      <SectionGrid key={i}>
                        <SectionItem>
                          <ProductCard data={product.node}  openQuoteModal={() => setQuoteVisible(true)}  />
                        </SectionItem>
                      </SectionGrid>
                        
                    )}
                    }))
                    :
                   ( <div   style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }} >
                      <h6>There were no results for your query. Please try again.</h6>
                       </div>
                  )
                } 
                { checkData.length > 0 && (
                <PaginationNav className="pagination-nav">
                  <Pagination className="pagination">
                    {/* {!isFirst && (
                      <PageItem className="page-item">
                        <PageLink
                          className="page-link"
                          to={prevPage}
                          rel="prev"
                        >
                          {"Prev"}
                        </PageLink>
                      </PageItem>
                    )} */}
                    {Array.from({ length: pageNum1 }, (_, i) => (
                      <PageItem className="page-item"
                        key={`pagination-number${i + 1}`}
                        
                      >
                        <PageLink
                          className="page-link"
                          to={`/search${i === 0 ? "" : i + 1}?term=${name}`}
                        >
                          {i + 1}
                        </PageLink>
                      </PageItem>
                    ))}
                    {/* {!isLast && (
                      <PageItem className="page-item">
                        <PageLink
                        className="page-link"
                        to={nextPage}
                        rel="next"
                      >
                        {"Next"}
                      </PageLink>
                      </PageItem>
                    )} */}
                  </Pagination>
                </PaginationNav>
              )
            }
          </div>
        </SearchWrap>
      </Section>
      <QuotePopup 
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
        /> 
      </Layout>
    )
  }

export default Search 

export const pageQuery = graphql`
  query SearchQuery {
    allContentfulProduct  {
      edges {
        node {
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulSubCategory {
      edges {
        node {
          name
          mainCategory {
            name
          }
        }
      }
    }
    allContentfulProductApplication {
      edges {
        node {
          name
        }
      }
    }
    allContentfulRoofStyle {
      edges {
        node {
          name
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`

